.product {
  margin: 0;
}

.product--no-media {
  max-width: 57rem;
  margin: 0 auto;
}

.product__media-wrapper {
  padding-left: 0;
}

.product__info-wrapper {
  padding-left: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 749px) {
  .product-section {
    margin-top: 0;
  }
}

@media screen and (min-width: 750px) {
  .product__info-container--sticky {
    position: sticky;
    top: 3rem;
    z-index: 2;
  }

  .product__info-wrapper {
    padding-left: 5rem;
  }

  .product__media-container .slider-buttons {
    display: none;
  }
}

@media screen and (min-width: 990px) {
  .product:not(.product--no-media):not(.featured-product) .product__media-wrapper {
    max-width: 64%;
    width: calc(64% - 1rem / 2);
  }

  .product:not(.product--no-media):not(.featured-product) .product__info-wrapper {
    padding-left: 4rem;
    max-width: 36%;
    width: calc(36% - 1rem / 2);
  }
}

/* Dynamic checkout */

.shopify-payment-button__button {
  border-radius: 0;
  font-family: inherit;
  min-height: 4.6rem;
}

.shopify-payment-button__button [role="button"].focused,
.no-js .shopify-payment-button__button [role="button"]:focus {
  outline: .2rem solid rgba(var(--color-foreground),.5) !important;
  outline-offset: 0.3rem;
  box-shadow: 0 0 0 .1rem rgba(var(--color-button),var(--alpha-button-border)),0 0 0 .3rem rgb(var(--color-background)),0 0 .5rem .4rem rgba(var(--color-foreground),.3) !important;
}

.shopify-payment-button__button [role="button"]:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none !important;
}

.shopify-payment-button__button [role="button"]:focus-visible {
  outline: .2rem solid rgba(var(--color-foreground),.5) !important;
  box-shadow: 0 0 0 .1rem rgba(var(--color-button),var(--alpha-button-border)),0 0 0 .3rem rgb(var(--color-background)),0 0 .5rem .4rem rgba(var(--color-foreground),.3) !important;
}

.shopify-payment-button__button--unbranded {
  background-color: rgba(var(--color-button), var(--alpha-button-background));
  box-shadow: 0 0 0 0.1rem rgba(var(--color-button), var(--alpha-button-border));
  color: rgb(var(--color-button-text));
  font-size: 1.4rem;
  line-height: calc(1 + 0.2 / var(--font-body-scale));
  letter-spacing: 0.07rem;
}

.shopify-payment-button__button--unbranded::selection {
  background-color: rgba(var(--color-button-text), 0.3);
}

.shopify-payment-button__button--unbranded:hover,
.shopify-payment-button__button--unbranded:hover:not([disabled]) {
  background-color: rgba(var(--color-button), var(--alpha-button-background));
  box-shadow: 0 0 0 0.2rem rgba(var(--color-button), var(--alpha-button-border));
}

.shopify-payment-button__more-options {
  margin: 1.6rem 0 1rem;
  font-size: 1.2rem;
  line-height: calc(1 + 0.5 / var(--font-body-scale));
  letter-spacing: 0.05rem;
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

.shopify-payment-button__button--hidden {
  display: none;
}

/* Product form */

.product-form {
  display: block;
}

.product-form__error-message-wrapper:not([hidden]) {
  display: flex;
  align-items: flex-start;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.product-form__error-message-wrapper svg {
  flex-shrink: 0;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.7rem;
  margin-top: 0.5rem;
}

/* Form Elements */
.product-form__input {
  flex: 0 0 100%;
  padding: 0;
  margin: 0 0 1.2rem 0;
  max-width: 37rem;
  min-width: fit-content;
  border: none;
}

variant-radios,
variant-selects {
  display: block;
}

.product-form__input--dropdown {
  margin-bottom: 1.6rem;
}

.product-form__input .form__label {
  padding-left: 0;
}

fieldset.product-form__input .form__label {
  margin-bottom: 0.2rem;
}

.product-form__input input[type='radio'] {
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

.product-form__input input[type='radio'] + label {
  border: 0.1rem solid rgba(var(--color-foreground), 0.55);
  border-radius: 4rem;
  color: rgb(var(--color-foreground));
  display: inline-block;
  margin: 0.7rem 0.5rem 0.2rem 0;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  line-height: 1;
  text-align: center;
  transition: border var(--duration-short) ease;
  cursor: pointer;
}

.product-form__input input[type='radio'] + label:hover {
  border: 0.1rem solid rgb(var(--color-foreground));
}

.product-form__input input[type='radio']:checked + label {
  background-color: rgb(var(--color-foreground));
  color: rgb(var(--color-background));
}

@media screen and (forced-colors: active) {
  .product-form__input input[type=radio]:checked + label {
    text-decoration: underline;
  }
}

.product-form__input input[type='radio']:checked + label::selection {
  background-color: rgba(var(--color-background), 0.3);
}

.product-form__input input[type='radio']:disabled + label {
  border-color: rgba(var(--color-foreground), 0.1);
  color: rgba(var(--color-foreground), 0.4);
  text-decoration: line-through;
}
.product-form__input input[type='radio']:focus-visible + label {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0 0.5rem rgba(var(--color-foreground), 0.55);
}

/* Fallback */
.product-form__input input[type='radio'].focused + label,
.no-js .shopify-payment-button__button [role="button"]:focus + label {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0 0.5rem rgba(var(--color-foreground), 0.55);
}

/* No outline when focus-visible is available in the browser */
.no-js .product-form__input input[type='radio']:focus:not(:focus-visible) + label {
  box-shadow: none;
}

.product-form__input .select {
  max-width: 25rem;
}

.product-form__submit {
  margin-bottom: 1rem;
}

.no-js .product-form__submit.button--secondary {
  --color-button: var(--color-base-accent-1);
  --color-button-text: var(--color-base-solid-button-labels);
  --alpha-button-background: 1;
}

.product-form__submit[aria-disabled="true"] + .shopify-payment-button,
.product-form__submit[disabled] + .shopify-payment-button {
  display: none;
}

@media screen and (forced-colors: active) {
  .product-form__submit[aria-disabled="true"] {
    color: Window;
  }
}

/* Overrides */
.shopify-payment-button__more-options {
  color: rgb(var(--color-foreground));
}

.shopify-payment-button__button {
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
}

/* Product info */

.product__info-container > * + * {
  margin: 1.5rem 0;
}

.product__info-container .product-form,
.product__info-container .product__description {
  margin: 2.5rem 0;
}

.product__text {
  margin-bottom: 0;
}

a.product__text {
  display: block;
  text-decoration: none;
  color: rgba(var(--color-foreground), 0.75);
}

.product__text.caption-with-letter-spacing {
  text-transform: uppercase;
}

.product__title {
  word-break: break-word;
  margin-bottom: 1.5rem;
}

.product__title + .product__text.caption-with-letter-spacing {
  margin-top: -1.5rem;
}

.product__text.caption-with-letter-spacing + .product__title {
  margin-top: 0;
}

.product__accordion .accordion__content {
  padding: 0 1rem;
}

.product .price {
  align-items: flex-start;
}

.product .price .badge {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.product .price dl {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.product .price--sold-out .price__badge-sold-out {
  background: transparent;
  color: rgb(var(--color-background));
  border: none;
}

.product .price--sold-out .price__badge-sale {
  display: none;
}

@media screen and (min-width: 750px) {
  .product__info-container .price--on-sale .price-item--regular {
    font-size: 1.6rem;
  }

  .product__info-container > *:first-child {
    margin-top: 0;
  }
}

.product__description-title {
  font-weight: 600;
}

.product--no-media .product__title,
.product--no-media .product__text,
.product--no-media noscript .product-form__input,
.product--no-media .product__tax  {
  text-align: center;
}

.product__tax {
  margin-top: -1.4rem;
}

.product--no-media noscript .product-form__input,
.product--no-media .share-button {
  max-width: 100%;
}

.product--no-media fieldset.product-form__input,
.product--no-media .product-form__quantity,
.product--no-media .product-form__input--dropdown,
.product--no-media .share-button,
.product--no-media .product__view-details,
.product--no-media .product__pickup-availabilities,
.product--no-media .product-form {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.product--no-media .product-form {
  flex-direction: column;
}

.product--no-media .product-form > .form {
  max-width: 30rem;
  width: 100%;
}

.product--no-media .product-form__quantity,
.product--no-media .product-form__input--dropdown {
  flex-direction: column;
  max-width: 100%;
}

.product--no-media fieldset.product-form__input {
  flex-wrap: wrap;
  margin: 0 auto 1.2rem auto;
}

.product--no-media .product__info-container > modal-opener {
  display: block;
  text-align: center;
}

.product--no-media .product-popup-modal__button {
  padding-right: 0;
}

.product--no-media .price {
  justify-content: center;
}

.product--no-media .product__info-wrapper {
  padding-left: 0;
}

/* Product media */

@media screen and (max-width: 749px) {
  .product__media-list {
    margin-left: -2.5rem;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    width: calc(100% + 4rem);
  }

  .product__media-wrapper slider-component {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .slider.slider--mobile.product__media-list {
    padding-bottom: 0;
    margin-bottom: 0.5rem;
  }

  .slider.product__media-list::-webkit-scrollbar {
    height: 0.2rem;
    width: 0.2rem;
  }

  .product__media-list::-webkit-scrollbar-thumb {
    background-color: rgb(var(--color-foreground));
  }

  .product__media-list::-webkit-scrollbar-track {
    background-color: rgba(var(--color-foreground), 0.2);
  }

  .product__media-list .product__media-item {
    width: calc(100% - 3rem);
  }

  .slider.product__media-list .product__media-item:first-of-type {
    padding-left: 1.5rem;
  }
}

@media screen and (min-width: 750px) {
  .product__media-item:first-child {
    width: 100%;
  }

  .product-media-modal__content > .product__media-item--variant.product__media-item--variant {
    display: none;
  }

  .product-media-modal__content > .product__media-item--variant:first-child {
    display: block;
  }
}

.product__media-item--variant {
  display: none;
}

.product__media-item--variant:first-child {
  display: block;
}

@media screen and (max-width: 749px) {
  .product__media-item--variant:first-child {
    padding-right: 1.5rem;
  }
}

@media screen and (min-width: 750px) and (max-width: 989px) {
  .product__media-list .product__media-item:first-child {
    padding-left: 0;
  }

  .product__media-list .product__media-item {
    padding: 0 0 0.5rem;
    width: 100%;
  }
}

.product__media-icon .icon {
  width: 1.2rem;
  height: 1.4rem;
}

.product__media-icon {
  background-color: rgb(var(--color-background));
  border-radius: 50%;
  border: 0.1rem solid rgba(var(--color-foreground), 0.1);
  color: rgb(var(--color-foreground));
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  position: absolute;
  left: 1.5rem;
  top: 1.5rem;
  z-index: 1;
  transition: color var(--duration-short) ease,
    opacity var(--duration-short) ease;
}

.product__media-video .product__media-icon {
  opacity: 1;
}

.product__modal-opener--image .product__media-toggle:hover {
  cursor: zoom-in;
}

.product__modal-opener:hover .product__media-icon {
  border: 0.1rem solid rgba(var(--color-foreground), 0.1);
}

@media screen and (min-width: 750px) {
  .grid__item.product__media-item--full {
    width: 100%;
  }
}

@media screen and (min-width: 990px) {
  .product__modal-opener .product__media-icon {
    opacity: 0;
  }

  .product__modal-opener:hover .product__media-icon,
  .product__modal-opener:focus .product__media-icon {
    opacity: 1;
  }
}

.product__media-item > * {
  display: block;
  position: relative;
}

.product__media-toggle {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
}

.product-media-modal {
  background-color: rgb(var(--color-background));
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

.product-media-modal[open] {
  visibility: visible;
  opacity: 1;
  z-index: 101;
}

.product-media-modal__dialog {
  display: flex;
  align-items: center;
  height: 100vh;
}

.product-media-modal__content {
  max-height: 100vh;
  width: 100%;
  overflow: auto;
}

.product-media-modal__content > *:not(.active),
.product__media-list .deferred-media {
  display: none;
}

@media screen and (min-width: 750px) {
  .product-media-modal__content {
    padding-bottom: 2rem;
  }

  .product-media-modal__content > *:not(.active) {
    display: block;
  }

  .product__modal-opener:not(.product__modal-opener--image) {
    display: none;
  }

  .product__media-list .deferred-media {
    display: block;
  }
}

.product__media-list .deferred-media,
.product__media-list .product__modal-opener {
  border: 0.1rem solid rgba(var(--color-foreground), 0.04);
}

.product-media-modal__content > * {
  display: block;
  height: auto;
  margin: auto;
  border: 0.1rem solid rgba(var(--color-foreground), 0.04);
}

.product-media-modal__content .media {
  background: none;
}

.product-media-modal__model {
  width: 100%;
}

.product-media-modal__toggle {
  background-color: rgb(var(--color-background));
  border: 0.1rem solid rgba(var(--color-foreground), 0.1);
  border-radius: 50%;
  color: rgba(var(--color-foreground), 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: 2rem;
  padding: 1.2rem;
  position: fixed;
  z-index: 2;
  top: 2rem;
  width: 4rem;
}

.product-media-modal__content .deferred-media {
  width: 100%;
}

@media screen and (min-width: 750px) {
  .product-media-modal__content {
    padding: 2rem 11rem;
  }

  .product-media-modal__content > * {
    width: 100%;
  }

  .product-media-modal__content > * + * {
    margin-top: 2rem;
  }

  .product-media-modal__toggle {
    right: 5rem;
    top: 2.2rem;
  }
}

@media screen and (min-width: 990px) {
  .product-media-modal__content {
    padding: 2rem 11rem;
  }

  .product-media-modal__content > * + * {
    margin-top: 1.5rem;
  }

  .product-media-modal__content {
    padding-bottom: 1.5rem;
  }

  .product-media-modal__toggle {
    right: 5rem;
  }
}

.product-media-modal__toggle:hover {
  color: rgba(var(--color-foreground), 0.75);
}

.product-media-modal__toggle .icon {
  height: auto;
  margin: 0;
  width: 2.2rem;
}

/* Product popup */

.product-popup-modal {
  box-sizing: border-box;
  opacity: 0;
  position: fixed;
  visibility: hidden;
  z-index: -1;
  margin: 0 auto;
  top: 0;
  left: 0;
  overflow: auto;
  width: 100%;
  background: rgba(var(--color-foreground), 0.2);
  height: 100%;
}

.product-popup-modal[open] {
  opacity: 1;
  visibility: visible;
  z-index: 101;
}

.product-popup-modal__content {
  background-color: rgb(var(--color-background));
  overflow: auto;
  height: 80%;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5rem;
  width: 92%;
  position: absolute;
  top: 0;
  padding: 0 1.5rem 0 3rem;
}

@media screen and (min-width: 750px) {
  .product-popup-modal__content {
    padding-right: 1.5rem;
    margin-top: 10rem;
    width: 70%;
    padding: 0 3rem;
  }
}

.product-popup-modal__content img {
  max-width: 100%;
}

@media screen and (max-width: 749px) {
  .product-popup-modal__content table {
    display: block;
    max-width: fit-content;
    overflow-x: auto;
    white-space: nowrap;
    margin: 0;
  }
}

.product-popup-modal__opener {
  display: inline-block;
}

.product-popup-modal__button {
  font-size: 1.6rem;
  padding-right: 1.3rem;
  padding-left: 0;
  height: 4.4rem;
  text-underline-offset: 0.3rem;
  text-decoration-thickness: 0.1rem;
  transition: text-decoration-thickness var(--duration-short) ease;
}

.product-popup-modal__button:hover {
  text-decoration-thickness: 0.2rem;
}

.product-popup-modal__content-info {
  padding-right: 4.4rem;
}

.product-popup-modal__content-info > * {
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
}

@media screen and (max-width: 749px) {
  .product-popup-modal__content-info > * {
    max-height: 100%;
  }
}

.product-popup-modal__toggle {
  background-color: rgb(var(--color-background));
  border: 0.1rem solid rgba(var(--color-foreground), 0.1);
  border-radius: 50%;
  color: rgba(var(--color-foreground), 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: sticky;
  padding: 1.2rem;
  z-index: 2;
  top: 1.5rem;
  width: 4rem;
  margin: 0 0 0 auto;
}

.product-popup-modal__toggle:hover {
  color: rgba(var(--color-foreground), 0.75);
}

.product-popup-modal__toggle .icon {
  height: auto;
  margin: 0;
  width: 2.2rem;
}
